import { Box, Typography } from '@mui/material';
import _ from 'lodash';
import { FC, useEffect, useState } from 'react';
import { t } from '@lingui/macro';

// Assets
import FbIcon from 'assets/icons/brand/facebook_color.svg';
import IgIcon from 'assets/icons/brand/instagram_color.png';
import YtIcon from 'assets/icons/brand/youtube_color.png';
import LiIcon from 'assets/icons/brand/linkedin_color.png';
import ConnectorAuthValues from 'components/legacy/connectors/connectorAuthValues';
import connectorStorageKeys from 'components/legacy/connectors/connectorStorageKeys';
import useStorageValueCollection from 'components/legacy/hooks/useStorageValueCollection';

// Components
import ReportPartContainer from '../common';

// Services
import Image from 'next/image';
import { useGetKeyFigureQuery } from 'components/legacy/services/socialData';

interface ReportSocialNetworkConnectedProps {
	organizationId: number;
	displayLink?: boolean;
}

const ReportSocialNetworkConnected: FC<ReportSocialNetworkConnectedProps> = (props) => {
	if (props.organizationId === null || props.organizationId === undefined)
		throw new Error('ReportSocialNetworkConnected organizationid empty');

	const [mapping] = useStorageValueCollection(
		[
			connectorStorageKeys.facebook.connectionStatus,
			connectorStorageKeys.instagram.connectionStatus,
			connectorStorageKeys.linkedin.connectionStatus,
			connectorStorageKeys.youtube.connectionStatus
		],
		props.organizationId
	);

	const [dataFb, setDataFb] = useState<any>();
	const [dataIg, setDataIg] = useState<any>();
	const [dataLi, setDataLi] = useState<any>();
	const [dataYt, setDataYt] = useState<any>();
	const [loading, setLoading] = useState(true);

	const { data: fb, isLoading: loadingFb } = useGetKeyFigureQuery({
		socialNetwork: 'facebook'
	});
	const { data: ig, isLoading: loadingIg } = useGetKeyFigureQuery({
		socialNetwork: 'instagram'
	});
	const { data: yt, isLoading: loadingYt } = useGetKeyFigureQuery({
		socialNetwork: 'youtube'
	});
	const { data: linkedin, isLoading: loadingLinkedin } = useGetKeyFigureQuery({
		socialNetwork: 'linkedin'
	});

	useEffect(() => {
		(async () => {
			setDataFb(fb?.data?.keyFigures);
			setDataIg(ig?.data?.keyFigures);
			setDataLi(linkedin?.data?.keyFigures);
			setDataYt(yt?.data?.keyFigures);
			setLoading(false);
		})();
	}, [props.organizationId, fb, ig, linkedin, yt]);

	if (loading || loadingFb || loadingIg || loadingLinkedin || loadingYt) {
		return null;
	}

	if (!dataFb && !dataIg && !dataYt && !!dataLi) {
		return null;
	}

	// if there isn't any at least on social network connected, hide this block
	if (!_.find(_.values(mapping), (storageValue) => storageValue.value === ConnectorAuthValues.succeeded)) {
		return (
			<ReportPartContainer displayLink={false} route={'/city/settings'}>
				<Typography sx={{ textAlign: 'center', pt: 2 }} variant="h4">
					{t`Réseaux sociaux`}
				</Typography>
				<Typography sx={{ textAlign: 'center' }}>
					{t`Veuillez connecter au moins un de vos réseaux sociaux pour avoir accès au rapport.`}
				</Typography>
			</ReportPartContainer>
		);
	}

	return (
		<ReportPartContainer displayLink={false} route={'/city/settings'}>
			<Typography sx={{ textAlign: 'center', pt: 2 }} variant="h4">
				{t`Réseaux sociaux connectés`}
			</Typography>
			<Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', mt: 2 }}>
				<Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
					{mapping['facebook:connection-status'].value === 'succeeded' ? (
						<>
							<div style={{ margin: '8px' }}>
								<Image alt="facebook" src={FbIcon} height={32} width={32} />
							</div>

							<Typography sx={{ mt: 2 }}>
								{new Intl.NumberFormat().format(dataFb?.absoluteFollowers)} followers
							</Typography>
						</>
					) : (
						''
					)}
				</Box>
				<Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
					{mapping['instagram:connection-status'].value === 'succeeded' ? (
						<>
							<div style={{ margin: '8px' }}>
								<Image alt="instagram" src={IgIcon} height={32} width={32} />
							</div>
							<Typography sx={{ mt: 2 }}>
								{new Intl.NumberFormat().format(dataIg?.absoluteFollowers)} followers
							</Typography>
						</>
					) : (
						''
					)}
				</Box>
				<Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
					{mapping['linkedin:connection-status'].value === 'succeeded' ? (
						<>
							<div style={{ margin: '8px' }}>
								<Image alt="linkedin" src={LiIcon} height={32} width={32} />
							</div>
							<Typography sx={{ mt: 2 }}>
								{new Intl.NumberFormat().format(dataLi?.absoluteFollowers)} followers
							</Typography>
						</>
					) : (
						''
					)}
				</Box>
				<Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
					{mapping['youtube:connection-status'].value === 'succeeded' ? (
						<>
							<div style={{ margin: '8px' }}>
								<Image alt="youtube" src={YtIcon} height={32} width={32} />
							</div>
							<Typography sx={{ mt: 2 }}>
								{new Intl.NumberFormat().format(dataYt?.absoluteFollowers)} followers
							</Typography>
						</>
					) : (
						''
					)}
				</Box>
			</Box>
		</ReportPartContainer>
	);
};

export default ReportSocialNetworkConnected;
