import _ from 'lodash';
import { Box, Theme, Typography } from '@mui/material';
import { t } from '@lingui/macro';
import moment from 'moment';

// Constants
import ConnectorAuthValues from 'components/legacy/connectors/connectorAuthValues';
import connectorStorageKeys from 'components/legacy/connectors/connectorStorageKeys';

// Hooks
import useSocialDataHistory from 'components/legacy/hooks/useSocialDataHistory';
import useStorageValueCollection from 'components/legacy/hooks/useStorageValueCollection';

// Components
import ReportPartContainer from '../common';
import { useEffect, useState } from 'react';

const ReportFollowersProgress = (props) => {
	if (props.organizationId === null || props.organizationId === undefined)
		throw new Error('ReportFollowersProgress organizationid empty');

	const [mapping] = useStorageValueCollection(
		[
			connectorStorageKeys.facebook.connectionStatus,
			connectorStorageKeys.instagram.connectionStatus,
			connectorStorageKeys.linkedin.connectionStatus,
			connectorStorageKeys.youtube.connectionStatus
		],
		props.organizationId
	);

	const [data] = useSocialDataHistory({ days: 15, property: 'followers' }, props.organizationId);

	// if there isn't any at least on social network connected, hide this block
	if (!_.find(_.values(mapping), (storageValue) => storageValue.value === ConnectorAuthValues.succeeded)) {
		return null;
	}

	// set a break point 8 days ago
	const lastWeekThreshold = moment().subtract(8, 'days').toDate();

	const previousWeekData = _.filter(data, (d) => new Date(d.date) <= lastWeekThreshold);
	const currentPastWeekData = _.filter(data, (d) => new Date(d.date) > lastWeekThreshold);

	const previousWeekCount = _.sumBy(previousWeekData, (d) => d.followers);
	const currentPastWeekCount = _.sumBy(currentPastWeekData, (d) => d.followers);

	const difference = currentPastWeekCount - previousWeekCount;

	return (
		<ReportPartContainer displayLink={props.displayLink} route={'/redirect/socialNetwork-followers'}>
			<Typography sx={{ textAlign: 'center', pt: 2 }} variant="h4">
				{t`Followers gagnés*`}
			</Typography>
			<Typography sx={{ textAlign: 'center' }}>{t`sur tous vos réseaux sociaux`}</Typography>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'row',
					py: 2
				}}
			>
				<Typography
					sx={{
						color: (theme) => theme.palette.primary.main,
						textAlign: 'center',
						fontFamily: 'Inter, sans-serif',
						fontSize: 58,
						fontStyle: 'normal',
						fontWeight: 700,
						letterSpacing: 'normal',
						lineHeight: 50 / 42
					}}
				>
					{new Intl.NumberFormat().format(currentPastWeekCount)}
				</Typography>
				<Typography
					sx={{
						ml: 1,
						textAlign: 'center',
						fontFamily: 'Inter, sans-serif',
						fontSize: 24,
						fontStyle: 'normal',
						fontWeight: 700,
						letterSpacing: 'normal',
						lineHeight: 50 / 42,
						color: (theme) =>
							difference === null
								? theme.palette.text.primary
								: difference > 0
								? theme.palette.success.main
								: difference === 0
								? theme.palette.text.primary
								: difference < 0
								? theme.palette.error.main
								: theme.palette.error.main
					}}
				>
					{difference === null
						? '--'
						: difference > 0
						? '+' + difference
						: difference === 0
						? '--'
						: difference < 0
						? difference
						: difference}
				</Typography>
			</Box>
		</ReportPartContainer>
	);
};

export default ReportFollowersProgress;
