// Externals
import { Box, CircularProgress, Typography } from '@mui/material';
import { plural, t } from '@lingui/macro';

// Components
import ReportPartContainer from '../common';

// Store
import moment from 'moment';
import UptimeRobotDetailsDto from 'components/legacy/components/blocks/uptimerobot/models/uptimeRobotDetails.dto';
import { useEffect, useState } from 'react';
import { useGetDetailsUptimeRobotMutation } from 'components/legacy/services/connectors';

// Services

const ReportDownTimeDuration = (props) => {
	const daysTotal = 7;
	const [loading, setLoading] = useState(true);
	const [durationDown, setDurationDown] = useState<number>(0);

	const [getDetailsUptimeRobot] = useGetDetailsUptimeRobotMutation();

	const totalDays = t({
		message: plural(moment.duration(durationDown, 'seconds').days(), {
			one: `# jour`,
			other: `# jours`
		})
	});

	// Date from 7 days ago
	const oneWeekAgo = new Date();
	oneWeekAgo.setDate(oneWeekAgo.getDate() - daysTotal);

	const [uptimeRobotDetails, SetUptimeRobotDetails] = useState<UptimeRobotDetailsDto>(null);

	useEffect(() => {
		(async () => {
			let response = await getDetailsUptimeRobot({ organizationId: props.organizationId }).unwrap();
			const data = response.data as UptimeRobotDetailsDto;
			SetUptimeRobotDetails(response.data);

			// Calculates the sum of durations for elements whose date is "daysTotal" days ago
			const totalDurationForLastWeek = data.histories.reduce((acc, curr) => {
				if (new Date(curr.datetime * 1000) >= oneWeekAgo) {
					return acc + curr.duration;
				}
				return acc;
			}, 0);

			setDurationDown(totalDurationForLastWeek);
			setLoading(false);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.organizationId]);

	return (
		// Display only if url exist and displayLink is true(force display on report central)
		(props.displayLink !== false || (uptimeRobotDetails && uptimeRobotDetails?.url)) && (
			<ReportPartContainer displayLink={props.displayLink} route={'/redirect/uptimerobot-recap'}>
				<Typography sx={{ textAlign: 'center', pt: 2 }} variant="h5">
					{t`Temps hors-ligne`}
				</Typography>
				<Typography sx={{ textAlign: 'center' }}>
					{t`Durant les`} {daysTotal} {t`derniers jours`}
				</Typography>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						flexDirection: 'row',
						py: 2
					}}
				>
					{loading ? (
						<CircularProgress />
					) : (
						<Typography
							sx={{
								color: (theme) =>
									durationDown > 3600
										? theme.palette.error.main
										: durationDown >= 601 && durationDown <= 3600
										? theme.palette.orange.main
										: durationDown >= 60 && durationDown <= 600
										? theme.palette.warning.main
										: durationDown === 0
										? theme.palette.success.main
										: theme.palette.text.primary,
								textAlign: 'center',
								fontFamily: 'Inter, sans-serif',
								fontSize: 58,
								fontStyle: 'normal',
								fontWeight: 700,
								letterSpacing: 'normal',
								lineHeight: 50 / 42
							}}
						>
							{durationDown === 0
								? '0 min'
								: durationDown >= 86400 // 86400 = 1 day
								? totalDays
								: moment
										.utc(moment.duration(durationDown, 'seconds').asMilliseconds())
										.format('HH[h ]mm[m ]ss[s]')
										.replace('00h ', '')
										.replace('00min ', '')}
						</Typography>
					)}
				</Box>
			</ReportPartContainer>
		)
	);
};

export default ReportDownTimeDuration;
