// React
import { useEffect, useState } from 'react';

// Externals
import { Typography } from '@mui/material';
import { t } from '@lingui/macro';

// Components
import ReportPartContainer from '../common';
import { useGetCountRemainingQuestionsQuery } from 'components/legacy/services/organizations';

// Services

const ReportQuestions = (props) => {
	const { data: remaining, isLoading: loadingRemaining } = useGetCountRemainingQuestionsQuery({});

	if (remaining?.data?.remainingQuestionsCount === null) return null;

	return (
		<ReportPartContainer displayLink={false} route={'/city/central/todo-list'}>
			<Typography sx={{ textAlign: 'center', pt: 2 }} variant="h4">
				{t`Questions non répondues`}
			</Typography>
			<Typography
				sx={{
					textAlign: 'center',
					py: 2,
					color: (theme) => theme.palette.primary.main,
					fontFamily: 'Inter, sans-serif',
					fontSize: 58,
					fontStyle: 'normal',
					fontWeight: 700,
					letterSpacing: 'normal',
					lineHeight: 50 / 42
				}}
			>
				{remaining?.data?.remainingQuestionsCount}
			</Typography>
		</ReportPartContainer>
	);
};

export default ReportQuestions;
