// Externals
import { Typography } from '@mui/material';
import { t } from '@lingui/macro';

// Components
import ReportPartContainer from '../common';

// Query
import { useGetOrganizationDetailsQuery } from 'components/legacy/services/organizations';

const ReportScore = (props) => {
	const orgaId = Number(window.localStorage.getItem('organizationId'));
	const { data: organizationDetails, isLoading: loadingOrganizationDetails } = useGetOrganizationDetailsQuery({
		id: orgaId
	});

	const citizenCount = Math.round(organizationDetails?.data?.organization?.score);

	return (
		<ReportPartContainer displayLink={false} route={'/city/central/todo-list'}>
			<Typography sx={{ textAlign: 'center', pt: 2 }} variant="h4">
				{t`Nombre d'habitants`}
			</Typography>
			<Typography
				sx={{
					textAlign: 'center',
					color: (theme) => theme.palette.primary.main,
					fontFamily: 'Inter, sans-serif',
					fontSize: 58,
					fontStyle: 'normal',
					fontWeight: 700,
					letterSpacing: 'normal',
					py: 2,
					lineHeight: 50 / 42
				}}
			>
				{new Intl.NumberFormat().format(citizenCount)}
			</Typography>
		</ReportPartContainer>
	);
};

export default ReportScore;
