// React

// Hooks
import useStorageValue from 'components/legacy/hooks/useStorageValue';

// Redux
import { useSelector } from 'components/legacy/store';

// Externals
import { Typography } from '@mui/material';
import { t } from '@lingui/macro';

// Models
import connectorStorageKeys from 'components/legacy/connectors/connectorStorageKeys';
import { BreachDto } from 'components/legacy/components/blocks/haveibeenpwned';
import ReportPartContainer from '../common';
import { useGetUserProfileQuery } from 'components/legacy/services/userProfiles';

export interface ResolvedDto {
	email: string;
	breachName: string;
	status: boolean;
}
const BREACHES_RESOLVED = 'haveibeenpwned:breaches-resolved';

const ReportHibpRecap = (props) => {
	if (props.organizationId === null || props.organizationId === undefined)
		throw new Error('ReportHibpRecap organizationid empty');

	const [breachesData] = useStorageValue<Array<BreachDto>>(
		connectorStorageKeys.hibp.breachesData,
		props.organizationId
	);
	const { data: userProfile, isLoading: loadingUserProfile } = useGetUserProfileQuery({});

	// TODO : only display breaches that haven't been handled by the user
	const breaches = breachesData.value ? breachesData.value : [];
	const [breachesResolved] = useStorageValue<Array<ResolvedDto>>(BREACHES_RESOLVED, props.organizationId);
	if (breachesResolved.loading === false) {
		if (!breachesResolved.value) {
			breachesResolved.value = [];
		}
	}

	//if email get total by email else get total.
	const breachesCount = userProfile?.data?.userProfile?.email
		? breaches.filter((x) => x.email === userProfile?.data?.userProfile?.email).length -
		  breachesResolved?.value?.filter((b) => b.status && b.email === userProfile?.data?.userProfile?.email).length
		: breaches.length - breachesResolved?.value?.filter((b) => b.status).length;

	if (breachesCount === 0 || breachesData.loading) {
		return null;
	}

	return (
		<ReportPartContainer displayLink={false} route={'/city/professional/professional'}>
			<Typography sx={{ textAlign: 'center', pt: 2 }} variant="h4">
				{t`Brèche(s) de sécurité`}
			</Typography>
			{userProfile?.data?.userProfile?.email && (
				<Typography sx={{ textAlign: 'center' }}>
					{t`Votre email : `} {userProfile?.data?.userProfile?.email}
				</Typography>
			)}
			{!userProfile?.data?.userProfile?.email && (
				<Typography sx={{ textAlign: 'center' }}>{t`Sur votre adresse email`}</Typography>
			)}
			<Typography
				sx={{
					textAlign: 'center',
					fontFamily: 'Inter, sans-serif',
					fontSize: 58,
					fontStyle: 'normal',
					fontWeight: 700,
					letterSpacing: 'normal',
					lineHeight: 50 / 42,
					color: breachesCount === 0 ? (theme) => theme.palette.success.main : (theme) => theme.palette.error.main
				}}
			>
				{breachesCount}
			</Typography>
		</ReportPartContainer>
	);
};

export default ReportHibpRecap;
