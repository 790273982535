// Externals
import { Typography } from '@mui/material';
import moment from 'moment';
import { t } from '@lingui/macro';

// Hooks
import useStorageValue from 'components/legacy/hooks/useStorageValue';

// Models
import connectorStorageKeys from 'components/legacy/connectors/connectorStorageKeys';
import ReportPartContainer from '../common';

const ReportGoogleSessionDuration = (props) => {
	if (props.organizationId === null || props.organizationId === undefined)
		throw new Error('ReportGoogleSessionDuration organizationid empty');

	const [sessionsDataGA] = useStorageValue<any>(
		connectorStorageKeys.googleGa4.report.lastWeekDaySessions,
		props.organizationId
	);

	const sessionsData = sessionsDataGA.value ? sessionsDataGA : null;

	if (sessionsData === null)
		return (
			<ReportPartContainer displayLink={props.displayLink} route={'/redirect/google-ga-avgtime'}>
				<Typography sx={{ textAlign: 'center', pt: 2 }} variant="h4">
					{t`Google Analytics`}
				</Typography>
				<Typography sx={{ textAlign: 'center' }}>
					{t`Veuillez connecter ou créer votre compte pour avoir accès au rapport.`}
				</Typography>
			</ReportPartContainer>
		);

	const duration = moment(sessionsData.value.duration * 1000).format('mm[min ]ss[s]');

	const differenceTransform = moment(
		sessionsData.value.duration * 1000 - (sessionsData.value.duration / sessionsData.value.durationProgression) * 1000
	).format('mm[min ]ss[s]');

	const difference =
		sessionsData.value.duration * 1000 - (sessionsData.value.duration / sessionsData.value.durationProgression) * 1000;

	return (
		<ReportPartContainer displayLink={props.displayLink} route={'/redirect/google-ga-avgtime'}>
			<Typography sx={{ textAlign: 'center', pt: 2 }} variant="h4">
				{t`Temps moyen par visite*`}
			</Typography>
			<Typography
				sx={{
					textAlign: 'center',
					py: 2,
					fontFamily: 'Inter, sans-serif',
					fontSize: 58,
					fontStyle: 'normal',
					fontWeight: 700,
					color: (theme) => theme.palette.primary.main,
					letterSpacing: 'normal',
					lineHeight: 50 / 42
				}}
			>
				{duration}
			</Typography>
			<Typography
				sx={{
					textAlign: 'center',
					fontFamily: 'Inter, sans-serif',
					fontSize: 24,
					fontStyle: 'normal',
					fontWeight: 700,
					letterSpacing: 'normal',
					lineHeight: 50 / 42,
					color: (theme) =>
						difference === null
							? theme.palette.text.primary
							: difference > 0
							? theme.palette.success.main
							: difference === 0
							? theme.palette.text.primary
							: difference < 0
							? theme.palette.error.main
							: theme.palette.error.main
				}}
			>
				{difference === null
					? '--'
					: difference > 0
					? '+' + differenceTransform
					: difference === 0
					? '--'
					: difference < 0
					? '-' + differenceTransform
					: difference}
			</Typography>
		</ReportPartContainer>
	);
};

export default ReportGoogleSessionDuration;
