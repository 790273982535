// Hooks
import useStorageValue from 'components/legacy/hooks/useStorageValue';

// Redux
import { useSelector } from 'components/legacy/store';

// Externals
import { Box, Typography } from '@mui/material';
import { Trans, t } from '@lingui/macro';

// Models
import connectorStorageKeys from 'components/legacy/connectors/connectorStorageKeys';
import ReportPartContainer from '../common';
import { domainTech } from 'components/legacy/services/organizations/domain.tech';
import { useEffect, useState } from 'react';
import { DomainInfo } from 'components/legacy/models/domain-tech.dto';
import DisplayVulnerabilities from 'components/legacy/components/blocks/domain-tech/components/DispayVulnerabilities';
import Severity from 'components/legacy/models/types/severitiesType';
import { useGetOrganizationDetailsQuery } from 'components/legacy/services/organizations';

const ReportDomainVulnerabilities = (props) => {
	const [mozdaStorage] = useStorageValue<any>(connectorStorageKeys.seo.mozda, props.organizationId);
	const [domainInfo, setDomainInfo] = useState<DomainInfo>(null);
	const [vulnerabilities, setVulnerabilities] = useState<{ [key: string]: number }>({});
	const [obsoleteTechnologies, setObsoleteTechnologies] = useState<number>(0);
	const { data: organizationDetails, isLoading: loadingOrganizationDetails } = useGetOrganizationDetailsQuery({
		id: props.organizationId
	});

	let website = organizationDetails?.data?.organization?.website;
	if (website == null) website = props.website;

	useEffect(() => {
		async function fetchData() {
			try {
				const result = await domainTech({ organizationId: props.organizationId });
				const jsonData: DomainInfo = JSON.parse(result.data as string);
				setDomainInfo(jsonData);

				const vulnerabilities: { [key: string]: number } = {};
				let obsoleteTechnologies = 0;

				jsonData?.Servers?.forEach((server) => {
					server.Websites?.forEach((website) => {
						website.Technologies?.forEach((tech) => {
							const techVulnerabilities = tech.Vulnerabilities || [];
							techVulnerabilities.forEach((vuln) => {
								const severity = vuln.Severity || Severity.NONE;
								vulnerabilities[severity] = (vulnerabilities[severity] || 0) + 1;
							});
							obsoleteTechnologies += tech.Version && tech.LatestVersion && tech.Version !== tech.LatestVersion ? 1 : 0;
						});
					});
				});
				setVulnerabilities(vulnerabilities);
				setObsoleteTechnologies(obsoleteTechnologies);
			} catch (error) {
				console.error('Erreur lors de la récupération des données:', error);
			}
		}

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (domainInfo === null) {
		return null;
	}

	return (
		<ReportPartContainer displayLink={props.displayLink} route={`/redirect/domain-tech`}>
			<Typography sx={{ textAlign: 'center', pt: 2 }} variant="h4">
				{t`Vulnérabilités du domaine`}
			</Typography>
			{website && <Typography sx={{ textAlign: 'center' }}>{website}</Typography>}
			<Typography sx={{ textAlign: 'center' }}>
				<Trans>Nombre de technologies obsolètes : </Trans>
				<Typography
					sx={{
						textAlign: 'center',
						py: 1,
						color: (theme) => theme.palette.primary.main,
						fontFamily: 'Inter, sans-serif',
						fontSize: 58,
						fontStyle: 'normal',
						fontWeight: 700,
						letterSpacing: 'normal',
						lineHeight: 50 / 42
					}}
				>
					{obsoleteTechnologies}
				</Typography>
			</Typography>

			<Box
				sx={{
					display: 'grid',
					gridTemplateColumns: 'repeat(auto-fit, minmax(1rem, 1fr))',
					gridAutoFlow: 'row',
					gridAutoColumns: '10rem',
					gap: '0.5rem',
					marginY: '2rem',
					justifyItems: 'center'
				}}
			>
				{Object.values(Severity).map((severity) => (
					<DisplayVulnerabilities
						key={severity}
						value={vulnerabilities[severity] || 0}
						severity={severity}
						forceDisplay={vulnerabilities[severity] > 0}
						displayTag={true}
					/>
				))}
			</Box>
		</ReportPartContainer>
	);
};

export default ReportDomainVulnerabilities;
