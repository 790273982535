import { useEffect, useState } from 'react';

// Internals
import { ICustomBlock, UserComponentProps } from '../index';
import { Header } from 'components/legacy/pages/audit/questions/header';

// Externals
import _ from 'lodash';
import { plural, t } from '@lingui/macro';
import {
	Box,
	CircularProgress,
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Typography
} from '@mui/material';
import Check from '@mui/icons-material/Check';
import Clear from '@mui/icons-material/Clear';

// Models
import { QuestionScoreDto } from 'components/legacy/models/questionScore.dto';

// Redux
import { useSelector } from 'components/legacy/store';

// Hooks
import useMoment from 'components/legacy/hooks/useMoment';

// Services

// Models
import UptimeRobotDetailsDto from './models/uptimeRobotDetails.dto';
import ReportPartContainer from '../report/parts/common';
import { useGetDetailsUptimeRobotMutation } from 'components/legacy/services/connectors';
import { useGetQuestionScoresQuery } from 'components/legacy/services/questions';
import { useParams } from 'react-router';
import React from 'react';

interface ComponentProps {
	name: string;
	description: string;
	connectorKey: string;
	questionKey: string;
}

const UserComponent = ({ name, description, connectorKey, questionKey }: ComponentProps) => {
	const organizationId = Number(window.localStorage.getItem('organizationId'));
	const { auditKey } = useParams();

	const { data: scoresQuery, isLoading: loadingScores } = useGetQuestionScoresQuery({
		organizationId,
		auditKey
	});

	const scores: QuestionScoreDto[] = useSelector<QuestionScoreDto[]>((state) =>
		scoresQuery?.data?.questions?.filter((s) => s.key === questionKey)
	);
	const score = _.sumBy(scores, (s) => s.score);

	const [data, setData] = useState<UptimeRobotDetailsDto>({} as UptimeRobotDetailsDto);
	const [loading, setLoading] = useState(true);
	const dateNow = Date.now();

	const moment = useMoment();

	const [getDetailsUptimeRobot] = useGetDetailsUptimeRobotMutation();

	const totalDays = t({
		message: plural(moment.duration(data?.status?.durationDown, 'seconds').days(), {
			one: `# jour`,
			other: `# jours`
		})
	});
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10); // nombre d'éléments par page
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	useEffect(() => {
		(async () => {
			let response = await getDetailsUptimeRobot({ organizationId: organizationId }).unwrap();
			setData(response.data);
			setLoading(false);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [organizationId]);

	var utcNow = moment.unix(moment.utc().unix());
	var monitorCreationDate = moment.unix(data.creationDate);

	var dayDiff = Math.ceil(moment.duration(utcNow.diff(monitorCreationDate)).asDays());
	if (dayDiff <= 1) dayDiff = 1; // at least consider 1 day
	if (dayDiff > 180) dayDiff = 180; // limit to last 30 day

	if (loading) {
		return (
			<>
				<Header isScoreVisible={false} label={description} organizationId={organizationId} title={name} />
				<CircularProgress />
			</>
		);
	}

	if (!data.status) {
		return (
			<>
				<Header isScoreVisible={true} label={description} organizationId={organizationId} score={score} title={name} />
				<Typography>{t`Aucune information disponible actuellement`}</Typography>
			</>
		);
	}

	return (
		<>
			<Header isScoreVisible={true} label={description} organizationId={organizationId} score={score} title={name} />
			<Grid columns={{ xs: 1, sm: 8, md: 12 }} container={true} spacing={{ xs: 2, md: 3 }}>
				<ReportPartContainer displayLink={false} route={''}>
					<Typography sx={{ textAlign: 'center', pt: 2 }} variant="h5">
						{t`Statut actuel de votre site :`}
					</Typography>
					{data.status.stat === '2' || data.status.stat === '0' || data.status.stat === null ? (
						<Typography
							sx={{ textAlign: 'center', pt: 2, color: (theme) => theme.palette.success.main }}
							variant="h4"
						>{t`En ligne`}</Typography>
					) : (
						<Typography sx={{ textAlign: 'center', pt: 2 }} variant="h4">
							{t`Hors ligne depuis`}{' '}
							{moment.duration((dateNow / 1000 - data.histories[data.histories.length - 1].dateTime) * 1000).humanize()}
						</Typography>
					)}

					<Box
						sx={{
							position: 'relative',
							display: 'flex',
							alignItems: 'center',
							height: '60px',
							width: '80px',
							cursor: 'pointer',
							mx: 'auto',
							mt: (theme) => theme.spacing(2)
						}}
					>
						<Box
							sx={{
								border: 3,
								borderColor: (theme) =>
									data.status.stat === '2' || data.status.stat === '0' || data.status.stat === null
										? theme.palette.success.light
										: theme.palette.error.light,
								display: 'block',
								background: (theme) =>
									data.status.stat === '2' || data.status.stat === '0' || data.status.stat === null
										? theme.palette.success.light
										: theme.palette.error.light,
								height: '100%',
								width: '100%',
								borderRadius: '20px'
							}}
						/>
						{data.status.stat === '2' || data.status.stat === '0' || data.status.stat === null ? (
							<Check
								sx={{
									fontSize: '50px',
									strokeWidth: 4,
									position: 'absolute',
									top: '50%',
									left: '50%',
									transform: 'translate(-50%,-50%)',
									color: (theme) => theme.palette.success.main
								}}
							/>
						) : (
							<Clear
								sx={{
									fontSize: '50px',
									position: 'absolute',
									top: '50%',
									left: '50%',
									transform: 'translate(-50%,-50%)',
									color: (theme) => theme.palette.error.main
								}}
							/>
						)}
					</Box>
				</ReportPartContainer>
				<ReportPartContainer displayLink={false} route={''}>
					<Typography sx={{ textAlign: 'center', pt: 2 }} variant="h5">
						{t`Temps hors-ligne`}
					</Typography>
					<Typography sx={{ textAlign: 'center' }}>
						{t`Durant les`} {dayDiff} {t`derniers jours`}
					</Typography>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'row',
							py: 2
						}}
					>
						<Typography
							sx={{
								color: (theme) =>
									data.status.durationDown >= 43200
										? theme.palette.error.main
										: data.status.durationDown >= 3601 && data.status.durationDown < 43200
										? theme.palette.orange.main
										: data.status.durationDown >= 60 && data.status.durationDown <= 3600
										? theme.palette.warning.main
										: data.status.durationDown === 0
										? theme.palette.success.main
										: theme.palette.text.primary,
								textAlign: 'center',
								fontFamily: 'Inter, sans-serif',
								fontSize: 58,
								fontStyle: 'normal',
								fontWeight: 700,
								letterSpacing: 'normal',
								lineHeight: 50 / 42
							}}
						>
							{data.status.durationDown === 0
								? '0 min'
								: data.status.durationDown >= 86400 // 86400 = 1 day
								? totalDays
								: moment
										.utc(moment.duration(data.status.durationDown, 'seconds').asMilliseconds())
										.format('HH[h ]mm[m ]ss[s]')
										.replace('00h ', '')
										.replace('00min ', '')}
						</Typography>
					</Box>
				</ReportPartContainer>
			</Grid>
			{data.histories.length !== 0 ? (
				<>
					<Typography sx={{ pt: 3, pb: 5 }} variant="h5">
						{dayDiff > 1
							? t`Derniers temps d'indisponibilité sur les ${dayDiff} derniers jours`
							: t`Derniers temps d'indisponibilité depuis ce jour`}
					</Typography>
					<Paper>
						<TableContainer component={Paper}>
							<Table aria-label="a dense table" size="small">
								<TableHead>
									<TableRow>
										<TableCell>{t`Date`}</TableCell>
										<TableCell>{t`Raison`}</TableCell>
										<TableCell align="right">{t`Durée`}</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{data.histories.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((history, index) => (
										<TableRow key={history.datetime} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
											<TableCell>{moment.unix(history.datetime).format('LLL')}</TableCell>
											<TableCell>{history.reason}</TableCell>
											<TableCell align="right">
												{history.duration === 0 || history.duration === ''
													? t`en cours`
													: moment
															.utc(moment.duration(history.duration, 'seconds').as('milliseconds'))
															.format('HH:mm:ss')}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
						{data.histories.length > 10 && (
							<TablePagination
								rowsPerPageOptions={[10]}
								component="div"
								count={data.histories.length}
								rowsPerPage={rowsPerPage}
								labelRowsPerPage={``}
								page={page}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
							/>
						)}
					</Paper>
				</>
			) : (
				''
			)}
		</>
	);
};

class UptimeRobotRecap implements ICustomBlock {
	description = () => t``;
	name = () => t`Disponibilité de votre site`;
	key = () => 'uptimerobot-recap';
	UserComponent: React.FC<UserComponentProps> = (props) => (
		<UserComponent
			connectorKey={this.key()}
			description={this.description()}
			name={this.name()}
			questionKey={props.questionKey}
		/>
	);
}

export default UptimeRobotRecap;
