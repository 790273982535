// Hooks
import useStorageValue from 'components/legacy/hooks/useStorageValue';

// Externals
import { Box, Typography } from '@mui/material';
import { t } from '@lingui/macro';

// Models
import connectorStorageKeys from 'components/legacy/connectors/connectorStorageKeys';
import ReportPartContainer from '../common';
import { useGetOrganizationDetailsQuery } from 'components/legacy/services/organizations';
import { useGetLastsHistoricalDataQuery } from 'components/legacy/services/historical-data';

const ReportSeoDomainAuthority = (props) => {
	const [pageStrengthStorage] = useStorageValue<any>(connectorStorageKeys.seo.domainStrength, props.organizationId);
	const { data: organizationDetails, isLoading: loadingOrganizationDetails } = useGetOrganizationDetailsQuery({
		id: props.organizationId
	});

	let website = organizationDetails?.data?.organization?.website;
	if (website == null) website = props.website;

	const { data: lastsHistoricalData, isLoading: loadingLastsHistoricalData } = useGetLastsHistoricalDataQuery({
		connectorName: 'seo',
		key: 'domain-strength',
		quantity: 2
	});

	const domainAuthorityLastPeriodProgress =
		lastsHistoricalData?.data?.values[0]?.value - lastsHistoricalData?.data?.values[1]?.value;

	if (pageStrengthStorage?.value === null)
		return (
			<ReportPartContainer displayLink={props.displayLink} route={`/settings/profile`}>
				<Typography sx={{ textAlign: 'center', pt: 2 }} variant="h4">
					{t`Votre site`}
				</Typography>
				<Typography sx={{ textAlign: 'center' }}>
					{t`Veuillez renseigner l'url de votre site pour avoir accès au rapport.`}
				</Typography>
			</ReportPartContainer>
		);

	return (
		<ReportPartContainer displayLink={props.displayLink} route={`/redirect/mozda`}>
			<Typography sx={{ textAlign: 'center', pt: 2 }} variant="h4">
				{t`Autorité de domaine`}
			</Typography>
			{website && <Typography sx={{ textAlign: 'center' }}>Votre site : {website}</Typography>}

			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					flexDirection: 'row',
					py: 2
				}}
			>
				<Typography
					sx={{
						textAlign: 'center',
						fontFamily: 'Inter, sans-serif',
						fontSize: 58,
						fontStyle: 'normal',
						fontWeight: 700,
						letterSpacing: 'normal',
						lineHeight: 50 / 42,
						color: (theme) => theme.palette.primary.main
					}}
				>
					{pageStrengthStorage?.value}/100
				</Typography>
				<Typography
					sx={{
						ml: 1,
						textAlign: 'center',
						fontFamily: 'Inter, sans-serif',
						fontSize: 20,
						fontStyle: 'normal',
						fontWeight: 700,
						letterSpacing: 'normal',
						color: (theme) =>
							domainAuthorityLastPeriodProgress === null
								? theme.palette.text.primary
								: domainAuthorityLastPeriodProgress > 0
								? theme.palette.success.main
								: domainAuthorityLastPeriodProgress === 0
								? theme.palette.text.primary
								: domainAuthorityLastPeriodProgress < 0
								? theme.palette.error.main
								: theme.palette.error.main
					}}
				>
					{domainAuthorityLastPeriodProgress === 0
						? '--'
						: domainAuthorityLastPeriodProgress < 0
						? domainAuthorityLastPeriodProgress
						: domainAuthorityLastPeriodProgress > 0
						? '+' + domainAuthorityLastPeriodProgress
						: ''}
				</Typography>
			</Box>
		</ReportPartContainer>
	);
};

export default ReportSeoDomainAuthority;
