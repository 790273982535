// Externals
import _ from 'lodash';
import { alpha, Box, Typography } from '@mui/material';
import { t } from '@lingui/macro';

// Hooks
import useStorageValue from 'components/legacy/hooks/useStorageValue';

// Models
import connectorStorageKeys from 'components/legacy/connectors/connectorStorageKeys';
import ReportPartContainer from '../common';

const ReportGoogleTopQueries = (props) => {
	if (props.organizationId === null || props.organizationId === undefined)
		throw new Error('ReportGoogleTopQueries organizationid empty');

	const [dataStorage] = useStorageValue<any>(
		connectorStorageKeys.googleSearch.report.TopTenQueriesOnLastWeek,
		props.organizationId
	);

	if (dataStorage.value === null) return null;

	const top5Queries = _.take(
		_.orderBy(dataStorage.value.rows, (r) => r.clicks),
		5
	);

	return (
		<ReportPartContainer displayLink={props.displayLink} route={'/redirect/google-search-top-10'}>
			<Typography sx={{ textAlign: 'center', mb: 2, pt: 2 }} variant="h4">
				{t`Mots clés populaires*`}
			</Typography>
			<Box sx={{ display: 'flex', flexDirection: 'column', pb: 2 }}>
				{top5Queries.map((element) => (
					<Typography
						key={element.keys[0]}
						sx={{
							width: '90%',
							textAlign: 'center',
							background: (theme) => alpha(theme.palette.primary.light, 0.1),
							borderRadius: '20px',
							mb: 1,
							ml: 'auto',
							mr: 'auto'
						}}
					>
						{element.keys}
					</Typography>
				))}
			</Box>
		</ReportPartContainer>
	);
};

export default ReportGoogleTopQueries;
